<template>
  <div class="infra-policy">
    <CyDetails
      :class="{ 'cy-details--horizontal' : isMdAndUpBreakpoint }"
      :item-id="_.get(formData, 'id')"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.showDeleteDialog"
      :can-cancel="canCancel"
      :can-save="canSave"
      :deleting="deleting"
      :loading="loading"
      :saving="saving"
      :delete-btn-text="$t('forms.btnRemove')"
      :is-owner="isOwner"
      :is-read-only="!canUpdateInfraPolicy">
      <template slot="details_form">
        <component :is="transitionComponent">
          <div
            v-show="!isExpanded"
            class="mr-6">
            <CyAlert
              theme="error"
              :content="errors"/>

            <CyAlert>
              <i18n path="alertMessage">
                <a
                  rel="noopener"
                  :href="$docLinks.infraPolicies.index"
                  class="cy-link"
                  target="_blank">{{ _.upperFirst($t('seeMore')) }}</a>
              </i18n>
            </CyAlert>

            <v-row>
              <v-col>
                <v-text-field
                  ref="nameField"
                  v-model="formData.name"
                  :label="$t('forms.fieldName')"
                  :readonly="!canUpdateInfraPolicy"
                  :disabled="!canUpdateInfraPolicy"
                  required
                  :error-messages="nameErrors"
                  class="required-field"
                  @focus="$v.formData.name.$reset()"
                  @blur="$v.formData.name.$touch()"/>
              </v-col>

              <v-col class="pl-6 mb-3 flex-grow-0">
                <v-switch
                  v-model="formData.enabled"
                  class="d-flex justify-end"
                  :label="formData.enabled ? $t('enabled') : $t('disabled')"
                  :readonly="!canUpdateInfraPolicy"
                  :disabled="!canUpdateInfraPolicy"
                  color="secondary"/>
              </v-col>
            </v-row>

            <v-textarea
              ref="descriptionField"
              v-model="formData.description"
              class="mb-4"
              :rows="2"
              :label="$t('forms.fieldDescription')"
              :hint="$t('descriptionHint')"
              :readonly="!canUpdateInfraPolicy"
              :disabled="!canUpdateInfraPolicy"
              persistent-hint
              auto-grow/>

            <CySelectOwner
              v-model="$v.formData.owner.$model"
              :readonly="!canUpdateInfraPolicy"
              :disabled="!canUpdateInfraPolicy"
              :current-owner="formData.owner"/>

            <p class="infra-policy__label font-size-sm mt-4 mb-0">
              {{ _.upperFirst($t('severities.severity')) }}
            </p>

            <CyBtnToggle
              v-model="formData.severity"
              :active-theme="severityButtonActiveTheme"
              :items="severityButtons"
              :readonly="!canUpdateInfraPolicy"
              class="infra-policy__severity"/>

            <p class="infra-policy__label font-size-sm mt-1">
              {{ $t('severityHint') }}
            </p>
          </div>
        </component>
      </template>
      <template slot="details_formFullWidth">
        <component :is="transitionComponent">
          <div :class="['code-container code-container--top ma-0', { 'code-container--expanded': isExpanded }]">
            <CyCodeEditor
              v-model="formData.body"
              :action-btn-icon="isExpanded ? 'fullscreen_exit' : 'fullscreen'"
              :action-btn-tooltip="isExpanded ? $t('minimizeEditor') : $t('maximizeEditor')"
              :debounce="200"
              :read-only="!canUpdateInfraPolicy"
              :code-lang="'rego'"
              @action-btn-clicked="$toggle.isExpanded"
              @focus="$v.formData.body.$reset()"
              @blur="$v.formData.body.$touch()"/>
            <span
              v-if="!_.isEmpty(codeEditorErrors)"
              class="infra-policy__error">{{ codeEditorErrors[0] }}</span>
            <div v-else>
              <span class="infra-policy__label">{{ $t('regoPolicyHint') }}</span>
              <a
                class="cy-link"
                target="_blank"
                rel="noopener noreferrer"
                :href="$docLinks.infraPolicies.openPolicyLanguage">
                {{ $t('regoPolicyHintUrl') }}
              </a>
            </div>
          </div>
        </component>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmRemoveHeader')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmRemoveSentence') }}</p>
      <h3>{{ formData.name }}</h3>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { VExpandTransition } from 'vuetify/lib'
import CyBtnToggle from '@/components/btn-toggle.vue'
import CyCodeEditor from '@/components/code-editor.vue'
import CyDetails from '@/components/details.vue'
import CySelectOwner from '@/components/select-owner.vue'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb, displayName, getOwnerUsername } from '@/utils/helpers'
import { required, minLength } from 'vuelidate/lib/validators'

export const severities = {
  advisory: 0,
  warning: 1,
  critical: 2,
}

export default {
  name: 'CyPageInfraPolicy',
  components: {
    CyBtnToggle,
    CyCodeEditor,
    CyDetails,
    CySelectOwner,
    VExpandTransition,
  },
  breadcrumb () {
    const header = this.$isCreationRoute
      ? this.$t('addInfraPolicy')
      : this.infraPolicy?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.infraPolicies'),
        name: 'infraPolicies',
      },
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    return this.$isCreationRoute
      ? { title: this.$t('addInfraPolicy') }
      : {
          title: this.infraPolicy?.name,
          createdAt: this.infraPolicy?.created_at,
          updatedAt: this.infraPolicy?.updated_at,
          loading: this.loading,
        }
  },
  props: {
    infraPolicyCanonical: {
      type: String,
      default: null,
    },
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        alphaNumeric: (name) => name ? REGEX.INFRA_POLICY_NAME.test(name) : true,
      },
      body: { required },
      enabled: {},
      owner: {},
    },
  },
  data: () => ({
    formData: {
      name: '',
      description: '',
      body: '',
      enabled: true,
      severity: 'advisory',
      owner: null,
    },
    saving: false,
    loading: true,
    isExpanded: false,
    deleting: false,
    showDeleteDialog: false,
  }),
  computed: {
    ...mapState('organization/infraPolicy', {
      errors: (state) => state.errors,
      infraPolicy: (state) => state.detail,
    }),
    isMdAndUpBreakpoint () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    // on vertical alignment show transition, on horizontal don't'
    transitionComponent () {
      return this.isMdAndUpBreakpoint ? 'div' : 'v-expand-transition'
    },
    canCancel () {
      return this.$hasDataChanged('formData')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.$invalid
        : !this.$v.$invalid && this.canCancel
    },
    canUpdateInfraPolicy () {
      return this.$isCreationRoute ||
        this.$cycloid.permissions.canDisplay('UpdateInfraPolicy', this.infraPolicyCanonical)
    },
    nameErrors () {
      const errors = []
      const { $dirty, required, minLength, alphaNumeric } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('forms.fieldMinLength', { number: 3 }))
      if (!alphaNumeric) errors.push(this.$t('forms.fieldNotAlphaNum'))

      return errors
    },
    codeEditorErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.body
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    severityButtonActiveTheme () {
      return {
        advisory: 'secondary',
        critical: 'error',
        warning: 'warning',
      }[this.formData.severity]
    },
    severityButtons () {
      return [
        {
          key: 'advisory',
          text: _.upperFirst(this.$t('severities.advisory')),
          theme: 'secondary',
          value: 'advisory',
        },
        {
          key: 'warning',
          text: _.upperFirst(this.$t('severities.warning')),
          theme: 'warning',
          value: 'warning',
        },
        {
          key: 'critical',
          text: _.upperFirst(this.$t('severities.critical')),
          theme: 'error',
          value: 'critical',
        },
      ]
    },
    isOwner () {
      return this.isEntityOwner(this.formData)
    },
    hasOwnerChanged () {
      if (this.$isCreationRoute || _.isEmpty(this.formData?.owner)) return false
      return !_.isEqual(
        getOwnerUsername(this.formData?.owner),
        getOwnerUsername(this.infraPolicy?.owner),
      )
    },
  },
  async mounted () {
    this.CLEAR_INFRA_POLICY_ERRORS()
    if (!this.$isCreationRoute) await this.fetchInfraPolicy()

    this.$toggle.loading(false)
  },
  destroyed () {
    this.RESET_INFRA_POLICY_STATE()
  },
  methods: {
    ...mapActions('organization/infraPolicy', [
      'GET_INFRA_POLICY',
      'CREATE_INFRA_POLICY',
      'UPDATE_INFRA_POLICY',
      'DELETE_INFRA_POLICY',
    ]),
    ...mapMutations('organization/infraPolicy', [
      'CLEAR_INFRA_POLICY_ERRORS',
      'RESET_INFRA_POLICY_STATE',
    ]),
    onCancel () {
      this.$resetData('formData')
      this.$v.$reset()
    },
    async onSave () {
      this.$toggle.saving(true)
      const { formData, username } = this

      const infraPolicy = {
        ...formData,
        ...(this.$isCreationRoute && { canonical: this.$getSlug(formData.name) }),
        owner: this.$isCreationRoute ? username : this.formData.owner?.username,
      }

      if (this.$isCreationRoute) await this.CREATE_INFRA_POLICY({ infraPolicy })
      else {
        const successMessage = this.hasOwnerChanged && this.$t('alerts.success.infraPolicy.reassigned', {
          infraPolicyName: formData.name,
          owner: displayName(formData.owner),
        })
        await this.UPDATE_INFRA_POLICY({ infraPolicy, successMessage })
      }

      this.$toggle.saving(false)

      if (!_.isEmpty(this.errors)) return

      this.navigateToList()
    },
    async onDelete () {
      this.$toggle.deleting(true)
      const { formData: infraPolicy, $router } = this
      await this.DELETE_INFRA_POLICY({ infraPolicy, $router })
      this.$toggle.deleting(false)
    },
    navigateToList () {
      this.$router.push({ name: 'infraPolicies' })
    },
    async fetchInfraPolicy () {
      const { infraPolicyCanonical } = this

      await this.GET_INFRA_POLICY({ infraPolicyCanonical })
      if (!_.isEmpty(this.errors) || _.isEmpty(this.infraPolicy)) return
      this.$v.formData.$model = _.cloneDeep(this.infraPolicy)
      this.$setOriginalData()
      this.$v.formData.$reset()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.infraPolicy',
        addInfraPolicy: 'Create InfraPolicy',
        confirmRemoveHeader: 'Remove InfraPolicy',
        confirmRemoveSentence: 'Are you sure that you want to remove this InfraPolicy?',
        descriptionHint: 'What is this InfraPolicy for?',
        maximizeEditor: 'Expand code editor',
        minimizeEditor: 'Collapse code editor',
        alertMessage: 'Implement enterprise policy checks for your cloud infrastructure. {0}',
        regoPolicyHint: 'Write your policy with Rego. See documentation and examples',
        regoPolicyHintUrl: 'here.',
        severityHint: 'This enables the policy to be a warning, allow overrides, or be mandatory.',
      },
      es: {
        title: '@:routes.infraPolicy',
        addInfraPolicy: 'Crear InfraPolicy',
        confirmRemoveHeader: 'Eliminar InfraPolicy',
        confirmRemoveSentence: '¿Está seguro de querer eliminar esta InfraPolicy?',
        descriptionHint: '¿Para qué es esta InfraPolicy?',
        maximizeEditor: 'Expandir editor de código',
        minimizeEditor: 'Colapsar editor de código',
        alertMessage: 'Implemente verificaciones de políticas empresariales para su infraestructura de nube. {0}',
        regoPolicyHint: 'Escriba su política con Rego. Consulte la documentación y los ejemplos',
        regoPolicyHintUrl: 'aquí.',
        severityHint: 'Esto permite que la política sea una advertencia, permita anulaciones o sea obligatoria.',
      },
      fr: {
        title: '@:routes.infraPolicy',
        addInfraPolicy: 'Créer InfraPolicy',
        confirmRemoveHeader: 'Retirer cette InfraPolicy',
        confirmRemoveSentence: 'Êtes-vous sûr de vouloir retirer cette InfraPolicy?',
        descriptionHint: 'À quoi sert cette InfraPolicy?',
        maximizeEditor: `Étendre l'éditeur de code`,
        minimizeEditor: `Réduire l'éditeur de code`,
        alertMessage: `Mettez en œuvre des règles de sécurité d'entreprise pour votre infrastructure cloud. {0}`,
        regoPolicyHint: 'Rédigez votre règle de sécurité avec Rego. Consultez la documentation et les exemples',
        regoPolicyHintUrl: 'ici.',
        severityHint: `Permet à la policy d'être un avertissement, d'autoriser les remplacements ou d'être obligatoire.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.infra-policy {
  &__severity {
    padding-left: 1px;
  }

  ::v-deep .cy-details {
    &__content {
      display: flex;
    }

    &__section {
      &-contained-wrapper {
        min-width: 400px;
        height: auto;
        overflow: initial;

        .cy-alert {
          display: flex;
          width: 100%;

          .v-icon {
            margin-right: 15px;
          }
        }
      }

      &--full-width {
        height: 100%;
        margin-top: 0;

        .code-container {
          height: 100%;

          &--top {
            display: flex;
            flex-direction: column;
          }

          ::v-deep .ace_gutter {
            z-index: 1 !important;
          }

          .code-editor {
            height: 100%;
            min-height: 90px;
            margin: 3px auto;
          }

          &--expanded {
            width: 100%;
          }
        }
      }
    }

    &--horizontal {
      .cy-details__section {
        // on horizontal layout, a div is rendered instead of transition
        > div {
          height: 100%;
        }
      }

      .cy-details__content {
        flex-direction: row;
      }
    }
  }

  &__label {
    color: cy-get-color("grey", "dark-2");
  }

  &__error {
    color: cy-get-color("error");
  }
}
</style>
