import { render, staticRenderFns } from "./infra-policy.vue?vue&type=template&id=eb1a9b26&scoped=true"
import script from "./infra-policy.vue?vue&type=script&lang=js"
export * from "./infra-policy.vue?vue&type=script&lang=js"
import style0 from "./infra-policy.vue?vue&type=style&index=0&id=eb1a9b26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb1a9b26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VRow,VSwitch,VTextField,VTextarea})
